import {connect} from 'react-redux';
import Modal from '../components/modal';
import {setDidSuccessfullySend, setSendInProgress, toggleModal} from "../actions";
import {setModalContent} from "../actions/index";

const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleModal: () => dispatch(toggleModal()),
    setSendInProgress: state => dispatch(setSendInProgress(state)),
    setDidSuccessfullySend: state => dispatch(setDidSuccessfullySend(state)),
    setModalContent: content => dispatch(setModalContent(content))
});

const mapStateToProps = state => ({
    isOpen: state.modal.isOpen,
    content: state.modal.content,
    isSendInProgress: state.modal.isSendInProgress,
    didSuccessfullySend: state.modal.didSuccessfullySend,
    pendingDistribution: state.recommendations.pendingCampaignID,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);