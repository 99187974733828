import {DateTime} from 'luxon';
import {rootUrl} from "./utils";
import React from "react";


const PROTOCOL_DEFAULT = null;

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const { API_HOSTNAME, API_PORT } = (() => {
  if (process.env.NODE_ENV === 'production') {
    const cneEnv = process.env.REACT_APP_CNE_ENV.toLowerCase();
    const isProd = cneEnv === "prod";

    return {
      API_HOSTNAME: isProd ? `realtime.climatecentral.org` : `rtc-${cneEnv}.climatecentral.org`,
      API_PORT: PROTOCOL_DEFAULT
    }
  } else {
    return {
      API_HOSTNAME: 'localhost',
      API_PORT: 8000
    }
  }
})();

export const API_VERSION = 'v1';

export const RECENT_EVENT_DAYS = 1;
export const DAYS_AHEAD = 7;
export const DATA_END_DATE = DateTime.utc().startOf("day").plus(({days: DAYS_AHEAD}));

export const dateDaysBefore = (date, days) => {
  return date.minus({days});
};
export const DATA_START_DATE = dateDaysBefore(DATA_END_DATE, 17);

export const RECOMMENDATION_COLUMNS = [
  {
    Header: 'Subscriber',
    columns: [
      {
        Header: 'City',
        accessor: 'event_location_name'
      },
      {
        Header: 'Last Name',
        accessor: 'subscriber_last_name',
        canGroupBy: false,
      },
      {
        Header: 'First Name',
        accessor: 'subscriber_first_name',
        canGroupBy: false,
      },
      {
        Header: 'Email',
        accessor: 'subscriber_email_address',
        canGroupBy: false,
      },
      {
        Header: 'Subscriber Type',
        accessor: 'subscriber_type',
      },
    ],
  },
  {
    Header: 'Story',
    columns: [
      {
        Header: 'Name',
        accessor: 'cm_story_title'
      },
      {
        Header: 'Pub Date',
        accessor: 'cm_story_publication_date',
      },
    ],
  },
  {
    Header: 'Event',
    columns: [
      { Header: 'Event Type',
        accessor: 'event_type',
      },
      { Header: 'Event Subtype',
        accessor: 'event_subtype',
      },
      {
        Header: 'Start Date',
        accessor: 'event_start_date',
      },
      {
        Header: 'Peak Date',
        accessor: 'event_peak_date',
      },
      {
        Header: 'Duration',
        accessor: 'event_duration',
      },
      {
        Header: 'Peak Value',
        accessor: 'event_value',
        canGroupBy: false,
        Cell: ({ cell: { value }, row }) => {
          if (!value) {
            return value;
          }
          let isTemperatureMeasure = row.values.event_type === 'TMAX' || row.values.event_type === 'HIMX' ||
              row.values.event_type === 'TMIN.LOW' || row.values.event_type === 'TMIN.HIGH';
          if (isTemperatureMeasure) {
            return `${(value / 10.0 * 9 / 5 + 32).toFixed(1)} ℉`;
          } else if (row.values.event_type === 'CFLD') {
            return `${(value * 3.28084).toFixed(1)} ft`;
          } else if (row.values.event_type === 'GT_LAKE_ICE') {
            return (100.0 * value).toFixed(2);
          } else {
            return value.toFixed(0);
          }
        }
      }
    ]
  }
];
