import React, { useEffect, useMemo, useState } from "react";
import { Table } from "./recommendation-table";
import styled from "styled-components";
import {RECOMMENDATION_COLUMNS} from "../constants";
import {
  rootUrl,
  ScheduledDistributionURL,
  updateScheduledDistribution
} from "../utils";
import Cookies from "js-cookie";

const Styles = styled.div`
  padding: 10px;
  background-color: white;
  overflow: auto;
  color: #6b6b76;

  table {
    border-spacing: 0;
    border: 1px solid #ededed;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.3rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    // position: relative;
  }
  table td {
    font-size: 0.8rem;
  },
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }
`;

function ScheduledDistributionTable() {
  // TODO: consider using Redux state for this component.
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);

  const columns = useMemo(() => {
    const columns = [...RECOMMENDATION_COLUMNS];

    columns.push(
      {
        Header: 'Preview',
        columns: [
          {
            Header: 'Preview',
            accessor: 'dmid',
            canGroupBy: false,
            Cell: ({ cell: { value } }) => {
              return <a href={`${rootUrl()}/view-email/${value}`}>View preview</a>;
            }
          },
          {
            Header: 'Scheduled Launch (ET)',
            accessor: 'launch_time',
            Cell: ({ cell: { value } }) => {
              return new Date(value).toLocaleString();
            }
          }
        ]
      },
    );
    return columns;
  }, [RECOMMENDATION_COLUMNS, rootUrl]);
  const initDeselected = useMemo(() => (
    new Set(data
      .filter(d => d.delivery_status === 'excluded')
      .map(d => d.id))
  ), [data]);

  const saveSelection = () => {
    const inclusions = data.filter(r =>  selection.includes(r.id)).map(r => r.id);
    const exclusions = data.filter(r => !selection.includes(r.id)).map(r => r.id);
    const csrftoken = Cookies.get('csrftoken');

    fetch(updateScheduledDistribution(), {
      credentials: "include",
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({inclusions, exclusions})
    })
      .then(res => res.json())
      .then(data => {
        alert(data.message);
      });
  };

  useEffect(() => {
    fetch(ScheduledDistributionURL(), {
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        // By default, set all records to be selected.
        setData(data.map(d => ({id: d.dmid, ...d})));
        setSelection(
          data
            .filter(d => d.delivery_status !== 'excluded')
            .map(d => d.dmid)
        )
      });
  }, [fetch, ScheduledDistributionURL, setData, setSelection]);

  return (
      <Styles>
          <h1>Scheduled for Distribution</h1>
          <hr />
          <Table
              columns={columns}
              data={data}
              setSelection={setSelection}
              initDeselected={initDeselected}
          />
          <br />
          <button onClick={saveSelection}>
              Save Selection
          </button>
      </Styles>
  );
}

export default ScheduledDistributionTable;
