import {DateTime} from 'luxon';
import {API_HOSTNAME, API_PORT, API_VERSION} from "./constants";

export const dateToStr = date => {
    return `${date.year}-${date.month}-${date.day}`
};

export const rootUrl = () => {
      let portType = typeof(API_PORT);
    if ((portType === "number") || (portType === "string")) {
        return `//${API_HOSTNAME}:${API_PORT}`;
    } else {
        return `//${API_HOSTNAME}`;
    }
};

export const apiRootUrl = () => {
  return `${rootUrl()}/api/${API_VERSION}`
};

export const EventURL = (startDate, endDate) => {
    let endsStr = dateToStr(endDate);
    let startStr = dateToStr(startDate);
    return `${apiRootUrl()}/event/?start_date=${startStr}&end_date=${endsStr}`; // eslint-disable-line
};

export const ProposalURL = (startDate, endDate) => {
    let endsStr = dateToStr(endDate);
    let startStr = dateToStr(startDate);
    return `${apiRootUrl()}/proposal/?start_date=${startStr}&end_date=${endsStr}`; // eslint-disable-line
};

export const ScheduledDistributionURL = () => {
    return `${apiRootUrl()}/scheduled-distribution/`
};

export const beginEmailCampaignURL = () => {
  return `${apiRootUrl()}/message-item/begin_email_campaign/`
};

export const doEmailCampaignURL = () => {
  return `${apiRootUrl()}/message-item/send_email_campaign/`
};

export const doCancelPendingDistributionsURL = (id) => {
  return `${apiRootUrl()}/campaign-distribution/${id}/`
};

export const updateScheduledDistribution = () => {
  return `${apiRootUrl()}/scheduled-distribution/update_exclusions/`
}

/**
 * From a list of recommendations, return an object mapping event types to a list of
 *  story titles, sorted descending by date published
 *
 * @param recommendations
 * @returns ({[""]})
 */
export function getStories(recommendations, eventTypes) {
    const stories = Object.values(eventTypes).reduce((obj, val) => {
        obj[val] = new Set();
        return obj;
    }, {});
    const storyDates = {};

    recommendations.forEach(r => {
        if (!(r.event_type in stories)) {
            return;
        }
        if (r.cm_story_title === null) {
          return;
        }
        stories[r.event_type].add(r.cm_story_title);
        storyDates[r.cm_story_title] = new Date(r.cm_story_publication_date);
    });

    Object.keys(stories).forEach(k => {
        stories[k] = Array.from(stories[k]);
        stories[k].sort((a, b) => storyDates[a] > storyDates[b] ? -1 : 1);
    });

    return stories;
}

/**
 * Get a start and end date from event start and day duration.
 * FIXME: understand event end date precisely. Should we use event.period instead?
 * @param eventStart
 * @param dayDuration
 */
export function eventBoundsFromDuration(eventStart, dayDuration) {
  let startDt = DateTime.fromISO(eventStart);

  return {
    eventStart: startDt,
    eventEnd: startDt.plus({days: dayDuration - 1})
  }
}

/**
 * Determine whether an event's bounds fall within given bounds,
 *  treating end dates as exclusive.
 * @param eventStart
 * @param eventEnd
 * @param startDate
 * @param endDate
 * @returns {boolean}
 */
export function isEventDateCurrent(eventStart, eventEnd, startDate, endDate) {
  if (eventEnd < startDate) {
    return false;
  } else if (eventStart > endDate) {
    return false;
  } else {
    return true;
  }
}

export function isEventIncluded(allEventTypes, selectedEventTypes, eventType, eventStart, eventEnd,
                                eventZscore, zScoreThreshold, startDate, endDate) {
  return (isEventDateCurrent(eventStart, eventEnd, startDate, endDate) &&
         selectedEventTypes.includes(eventType) && (eventType in allEventTypes) &&
         (allEventTypes[eventType].use_threshold ? eventZscore >= zScoreThreshold : true));
}
