import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import recommendations from '../features/recommendation/recommendationSlice';
import event from '../features/event/eventSlice';
import story from '../features/story/storySlice';
import modal from "../reducers/modal";
import { rtcApi } from '../services/rtc';


const store = configureStore({
    reducer: {
        story,
        recommendations,
        event,
        modal,
        [rtcApi.reducerPath]: rtcApi.reducer,
    },
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware({
            serializableCheck: false,
            // TODO: either add test support for Luxon's DateTime here (see example below)
            // or transform DateTime objects to millisecond timestamps before saving to store
            // and reverse when retrieving from the store
            // serializableCheck: {
            //     isSerializable: val => (isPlain(val) || (val instanceof DateTime) || (val instanceof FixedOffsetZone) ...)},
            // },
        }).concat(rtcApi.middleware)
    ),
});

setupListeners(store.dispatch);

export default store;
