import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components'
import { useExpanded, useFilters, useGroupBy, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import { setRecommendations, setSelection } from '../features/recommendation/recommendationSlice';
import { setStories } from '../features/story/storySlice';
import { eventBoundsFromDuration, isEventIncluded, ProposalURL } from "../utils";
import {DATA_END_DATE, DATA_START_DATE, RECOMMENDATION_COLUMNS} from "../constants";


const Styles = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  overflow-y: auto;
  color: #6b6b76;

  table {
    border-spacing: 0;
    border: 1px solid #ededed;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.3rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative;
  }
  table td {
    font-size: 0.8rem;
  },
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }
`;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// Be sure to pass our updateMyData and the skipReset option
export function Table({ columns, data, setSelection, initDeselected = new Set() }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const initialSelectedRowIds = React.useMemo(
    () => (
      data
        .reduce((obj, row, idx) => {
            return {
                ...obj,
                [idx]: !initDeselected.has(row.id),
            };
        }, {})
   ), [data, initDeselected]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    rows,
    flatRows,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
    },
  } = useTable(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
      defaultColumn,
      filterTypes,
      initialState: {
          // All rows selected by default.
          // Reduce data array to a single object like {0: true, 1: true, ... N: true},
          // indicating the row at each index is selected.
          selectedRowIds: initialSelectedRowIds,
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // Here we will use a plugin to add our selection column
    hooks => {
      hooks.visibleColumns.push(columns => {
        return [
          {
            id: 'selection',
            // Make this column a groupByBoundary. This ensures that groupBy columns
            // are placed after it
            groupByBoundary: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]
      })
    }
  );

  useEffect(() => {
    setSelection(
      flatRows
        .filter(r => selectedRowIds[r.id] === true)
        .map(r => r.original.id)
    )
  }, [setSelection, selectedFlatRows]);

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? '\u2BC1 ' : '\u2BC0 '}
                      </span>
                    ) : null}
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' \u2BC5'
                          : ' \u2BC6'
                        : ''}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? '\u2BC6' : '\u2BC8'}
                          </span>{' '}
                          {cell.render('Cell', { editable: false })} (
                          {row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell', { editable: true })
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const selectors = {
  eventTypes: createSelector(
    state => state.event.eventTypes,
    eventTypes => eventTypes.reduce((obj, item) => {
      obj[item.name] = item.name;
      return obj;
    }, {})
  ),
  allEventTypes: createSelector(
    state => state.event.eventTypes,
    eventTypes => eventTypes.reduce((obj, item) => {
      obj[item.name] = item;
      return obj;
    }, {})
  ),
  eventTypePriority: createSelector(
    state => state.event.eventTypes,
    eventTypes => eventTypes.map(item => item.name)
  ),
  recommendations: state => state.recommendations.recommendations,
  eventMinZScore: state => state.event.eventMinZScore,
  stories: state => state.story.activeStory,
  startDate: state => state.event.eventDates.startDate,
  endDate: state => state.event.eventDates.endDate,
  selectedEventTypes: state => state.event.selectedEventTypes,
};

function RecommendationTable() {
  const dispatch = useDispatch();

  const eventTypes = useSelector(selectors.eventTypes);
  const allEventTypes = useSelector(selectors.allEventTypes);
  const eventTypePriority = useSelector(selectors.eventTypePriority);
  const recommendations = useSelector(selectors.recommendations);
  const eventMinZScore = useSelector(selectors.eventMinZScore);
  const stories = useSelector(selectors.stories);
  const startDate = useSelector(selectors.startDate);
  const endDate = useSelector(selectors.endDate);
  const selectedEventTypes = useSelector(selectors.selectedEventTypes);

  const columns = useMemo(() => RECOMMENDATION_COLUMNS, [RECOMMENDATION_COLUMNS]);
  const initDeselected = useMemo(() => new Set(), []);
  const filteredRecommendations = useMemo(() => (
    [...recommendations]
      .filter(r => {
        const {eventStart, eventEnd} = eventBoundsFromDuration(r.event_start_date, r.event_duration);
        return (isEventIncluded(allEventTypes, selectedEventTypes, r.event_type, eventStart, eventEnd, r.event_anomaly, eventMinZScore, startDate, endDate) &&
          r.cm_story_title === stories[r.event_type])
      })
      .sort((a, b) => {
        return eventTypePriority.indexOf(a.event_type) - eventTypePriority.indexOf(b.event_type);
      })
  ), [recommendations, eventBoundsFromDuration, isEventIncluded, allEventTypes, selectedEventTypes, eventMinZScore, startDate, endDate, stories, eventTypePriority]);
  const onSelectionChange = useCallback(rows => {
    dispatch(setSelection(rows));
  }, [dispatch, setSelection]);

  useEffect(() => {
    if (isEmpty(eventTypes)) {
      return;
    }
    fetch(ProposalURL(DATA_START_DATE, DATA_END_DATE), {
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        // By default, set all records to be selected.
        dispatch(setRecommendations(
          data.map((d, i) => {
            return {id: i, ...d}
          })
        ));
        dispatch(setStories({recommendations: data, eventTypes}))
      });
  }, [dispatch, fetch, ProposalURL, DATA_START_DATE, DATA_END_DATE, setRecommendations, setStories, eventTypes]);

  return (
      <Styles>
          <Table
              columns={columns}
              data={filteredRecommendations}
              setSelection={onSelectionChange}
              initDeselected={initDeselected}
          />
      </Styles>
  );
}

export default RecommendationTable
