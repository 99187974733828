import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import { apiRootUrl } from '../utils';


export const rtcApi = createApi({
  reducerPath: 'rtcApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiRootUrl() }),
  endpoints: (builder) => ({
    getEventTypes: builder.query({
      query: () => ({ url: 'event-type/' }),
    }),
  }),
});
