import React, {Component} from 'react';
import MapGL, {Source, Layer} from "react-map-gl";
import {DateTime} from 'luxon';
import {DATA_END_DATE, DATA_START_DATE, MAPBOX_TOKEN} from "../constants";
import {eventBoundsFromDuration, EventURL, isEventIncluded} from "../utils";

export default class Map extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mapStyle: 'mapbox://styles/mapbox/light-v9',
            viewport: {
                longitude: -85,
                latitude: 39,
                zoom: 3,
                maxZoom: 20,
                pitch: 0,
                bearing: 0
            },
        };
    }

    componentDidMount() {
        fetch(EventURL(DATA_START_DATE, DATA_END_DATE), {
          credentials: "include"
        })
            .then(res => res.json())
            .then(data => {
                data.features.map(feat => {
                    // Convert start dates to timestamps for fast comparison and filtering
                    feat.properties.start_date = DateTime.fromISO(feat.properties.start_date).startOf("day");
                });
                this.props.setAllEvents(data);
            });
    }

  onViewportChange = viewport => this.setState({viewport});

  onStyleChange = mapStyle => this.setState({mapStyle});

  filterEvents() {
      const { events, startDate, endDate, eventTypes, selectedEventTypes, eventMinZScore} = this.props;

      const filteredEvents = events.features.filter(feat => {
        let {eventStart, eventEnd} = eventBoundsFromDuration(feat.properties.start_date, feat.properties.day_duration);
        return (
            isEventIncluded(eventTypes, selectedEventTypes, feat.properties.event_type, eventStart, eventEnd, feat.properties.max_zscore, eventMinZScore, startDate, endDate)
        );
      });

      return {
          type: "FeatureCollection",
          features: filteredEvents
      };
  }

  render() {
        const {viewport, mapStyle} = this.state;

        return (
            <MapGL
                {...viewport}
                mapStyle={mapStyle}
                width="100%"
                height="100%"
                onViewportChange={this.onViewportChange}
                mapboxApiAccessToken={MAPBOX_TOKEN}
            >
                <Source type="geojson" data={this.filterEvents()}>
                    <Layer
                        id="events"
                        type="circle"
                        paint={{
                            'circle-radius': {
                              'property': 'day_duration',
                              'stops': [
                                  [2, 5],
                                  [3, 10],
                                  [4, 15],
                                  [5, 20]
                              ]
                            },
                            'circle-opacity': 0.8,
                            'circle-color': [
                                'match',
                                ['get', 'event_type'],
                                ...this.props.eventColors,
                                '#000'
                            ],
                            'circle-stroke-width': 1,
                            'circle-stroke-color': '#fff'
                        }}
                    />
                </Source>
            </MapGL>
        );
    }
}
