import {ActionTypes} from "../actions";
import {combineReducers} from "redux";


const content = (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL_CONTENT:
      return action.payload.content;
    default:
      return state;
  }
};

const isOpen = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL_OPEN:
      return !!!state;
    default:
      return state;
  }
};

const isSendInProgress = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_SEND_IN_PROGRESS:
      return action.payload.state;
    default:
      return state;
  }
};

const didSuccessfullySend = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_DID_SUCCESSFULLY_SEND:
      return action.payload.state;
    default:
      return state;
  }
};

export default combineReducers({
  content,
  isOpen,
  isSendInProgress,
  didSuccessfullySend,
})