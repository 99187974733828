import { createSlice } from '@reduxjs/toolkit';


export const recommendationSlice = createSlice({
  name: 'recommendations',
  initialState: {
    recommendations: [],
    selection: [],
    pendingCampaignID: null,
  },
  reducers: {
    setRecommendations: (state, action) => {
      state.recommendations = action.payload;
    },
    setSelection: (state, action) => {
      state.selection = action.payload;
    },
    setPendingCampaign: (state, action) => {
      state.pendingCampaignID = action.payload
    }
  }
});

export const { setRecommendations, setSelection, setPendingCampaign } = recommendationSlice.actions;

export default recommendationSlice.reducer;
