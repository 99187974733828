import React from 'react';
import { useDispatch } from 'react-redux';
import {BrowserRouter, Route, Link, Switch, Redirect} from 'react-router-dom'

import styled from 'styled-components';

import ScheduledDistributions from "./components/scheduled-distribution-table";
import Console from "./components/console";
import { rtcApi } from './services/rtc';
import { setEventTypes } from './features/event/eventSlice';

const Style = styled.div`
  padding: 10px;
`;

function App() {
  const { data, error, isLoading } = rtcApi.endpoints.getEventTypes.useQuery();
  const dispatch = useDispatch();

  if (data) {
    dispatch(setEventTypes(data));
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Style>
            <h1>Realtime Climate Operations</h1>
            <ul>
              <li><Link to="/admin/">Database administration</Link></li>
              {error ? (<li>Error loading event types</li>)
              : isLoading ? (<li>Loading, please wait... Don't Panic...</li>)
              : (
                <>
                  <li><Link to="/console/">Event console</Link></li>
                  <li><Link to="/scheduled/">Scheduled distributions</Link></li>
                  <li><Link to="/upload_events/">Create custom events</Link></li>
                </>
              )}
            </ul>
          </Style>
        </Route>
        <Route path="/admin/" component={() => {
          // Set our location to a URL not served by the react app.
          const cneEnv = process.env.REACT_APP_CNE_ENV.toLowerCase();
          if (cneEnv == 'prod') {
            window.location.href = 'https://realtime.climatecentral.org/admin/';
          } else if (cneEnv == 'stage') {
            window.location.href = 'https://rtc-stage.climatecentral.org/admin/';
          } else {
            window.location.href = 'http://localhost:8000/admin/';
          }
        }}/>
        <Route path="/dashboard/">
          <Redirect to="/console/"/>
        </Route>
        <Route path="/console/">
          <Console />
        </Route>
        <Route path="/scheduled/">
          <ScheduledDistributions />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
