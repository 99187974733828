import React from 'react';
import ReactModal from 'react-modal';
import styles from 'styled-components';
import Cookies from 'js-cookie';
import {beginEmailCampaignURL, doCancelPendingDistributionsURL, doEmailCampaignURL} from "../utils";

const Style = styles.div`
  section {
    position: absolute;
    height: 90%;
    width: 90%;
    overflow-y: auto;
  }
  
  button {
    position: absolute;
    bottom: 10px;
    background-color: red;
    color: white;
  }
  
  button.disabled {
    background-color: gray;
  }
`;

function didCloseModal(pendingDistribution, setSendInProgress, didSuccessfullySend, setDidSuccessfullySend) {
  setSendInProgress(false)

  if( didSuccessfullySend ) {
    // if the distribution was sent, then we can skip this logic
    setDidSuccessfullySend(false) // clear this for the next distribution
    location.reload()
    return
  }

  let csrftoken = Cookies.get('csrftoken');

  fetch(doCancelPendingDistributionsURL(pendingDistribution), {
    credentials: "include",
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken
    },
    body: JSON.stringify({})
  })
      .then(res => res.json())
      .then(result =>
          console.log(`finished deletion: ${result}`)
      )
}

function doDistribution(campaignID, setContent, setSendInProgress, setDidSuccessfullySend) {
  let csrftoken = Cookies.get('csrftoken');

  setSendInProgress(true);
  setDidSuccessfullySend(false);

  fetch(doEmailCampaignURL(), {
    credentials: "include",
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken
    },
    body: JSON.stringify({campaign_distribution_id: campaignID})
  }).then(res => res.json())
      .then(data => {
        setContent('<h1>Success</h1><p>Email queued for sending.</p>')
        setDidSuccessfullySend(true)
      })
}

const Modal = ({isOpen, toggleModal, isSendInProgress, content = '', didSuccessfullySend, pendingDistribution, setSendInProgress, setDidSuccessfullySend, setModalContent}) => {
  return (
      <ReactModal
          style={{overlay: {zIndex: 1000}}}
          isOpen={isOpen}
          shouldCloseOnOverlayClick={true}
          onAfterClose={() => didCloseModal(pendingDistribution, setSendInProgress, didSuccessfullySend, setDidSuccessfullySend)}
          onRequestClose={toggleModal}
      >
        <Style>
          <section dangerouslySetInnerHTML={{__html: content}}>
          </section>
          <button disabled={isSendInProgress}
                  className={isSendInProgress ? "disabled" : ""}
                  onClick={() =>
              doDistribution(pendingDistribution, setModalContent, setSendInProgress, setDidSuccessfullySend)}>Send Email</button>
        </Style>
      </ReactModal>
  )
};

export default Modal;