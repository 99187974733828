import {connect} from 'react-redux';
import { setAllEvents } from '../features/event/eventSlice';
import Map from '../components/map';

const mapDispatchToProps = dispatch => ({
    setAllEvents: events => {
        dispatch(setAllEvents(events));
    },
});

const mapStateToProps = state => {
    const eventTypes = state.event.eventTypes.reduce((obj, item) => {
        obj[item.name] = item;
        return obj;
    }, {});
    const eventColors = state.event.eventTypes.reduce((arr, item) => {
        return [...arr, item.name, item.color];
      }, []);

    return {
        events: state.event.events,
        eventMinZScore: state.event.eventMinZScore,
        startDate: state.event.eventDates.startDate,
        endDate: state.event.eventDates.endDate,
        selectedEventTypes: state.event.selectedEventTypes,
        eventTypes,
        eventColors,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map);