import { createSlice } from '@reduxjs/toolkit';

import {DATA_END_DATE, dateDaysBefore, RECENT_EVENT_DAYS} from "../../constants";


const DEFAULT_EVENTS = {
  type: "FeatureCollection",
  features: []
};

const DEFAULT_MIN_EVENT_ZSCORE = 2;

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        activeEvent: {},
        events: DEFAULT_EVENTS,
        eventTypes: [],
        selectedEventTypes: [],
        eventDates: {
            startDate: dateDaysBefore(DATA_END_DATE, RECENT_EVENT_DAYS),
            endDate: DATA_END_DATE,
        },
        eventMinZScore: DEFAULT_MIN_EVENT_ZSCORE
    },
    reducers: {
        setActiveEvent: (state, action) => {
            state.activeEvent = action.payload;
        },
        setAllEvents: (state, action) => {
            state.events = action.payload;
        },
        setEventTypes: (state, action) => {
            state.eventTypes = action.payload;
        },
        setSelectedEventTypes: (state, action) => {
            state.selectedEventTypes = action.payload;
        },
        setEventDates: (state, action) => {
            state.eventDates = action.payload;
        },
        setEventMinZScore: (state, action) => {
            state.eventMinZScore = action.payload;
        },
    },
});

export const { setActiveEvent, setAllEvents, setEventTypes, setSelectedEventTypes, setEventDates, setEventMinZScore } = eventSlice.actions;

export default eventSlice.reducer;
