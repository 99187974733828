import React, {PureComponent} from 'react';
import {dateToStr, beginEmailCampaignURL, getStories} from "../utils";
import {dateDaysBefore, DAYS_AHEAD} from "../constants";
import Cookies from 'js-cookie';

const defaultContainer = ({children}) => <div className="control-panel">{children}</div>;

export default class StyleControls extends PureComponent {

  constructor(props) {
    super(props);
    const {numDays, dataStartDate, dataEndDate} = props;

    let i = 0;
    let curDate = dateDaysBefore(dataEndDate, i * numDays);
    const dateList = [];
    while (curDate > dataStartDate) {
      dateList.push(curDate);
      i++;
      curDate = dateDaysBefore(dataEndDate, i * numDays);
    }
    dateList.reverse();

    this.state = {
      dateList: dateList,
      curDateIdx: dateList.length - 1 - DAYS_AHEAD,
    }
  }

  componentDidMount() {
    this.props.setDate(this.state.dateList[this.state.curDateIdx]);
  }

  onDateChange(evt) {
    let dateIdx = evt.target.value;
    this.setState({curDateIdx: dateIdx});
    this.props.setDate(this.state.dateList[dateIdx]);
  };

  onZscoreChange(evt) {
    this.props.setEventMinZScore(evt.target.value);
  }

  onEventTypeChange(eventType) {
    const selected = {
      ...this.props.eventTypeSelection,
      [eventType]: !this.props.eventTypeSelection[eventType]
    };
    this.props.onEventTypeSelect(Object.keys(selected).filter(k => selected[k]));
  };

  onStoryChange(eventType, evt) {
    let storyTitle = evt.target.value;
    this.props.setStory(eventType, storyTitle);
  };

  prepareDistribution() {
    const recs = this.props.recommendations.filter(r => this.props.selection.includes(r.id));

    const csrftoken = Cookies.get('csrftoken');

    fetch(beginEmailCampaignURL(), {
      credentials: "include",
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({message_items: recs})
    }).then(res => res.json())
    .then(data => {
      if( data.errorMessage ) {
        alert(data.errorMessage)
      }
      else {
        let html_content = data.previews.join('<br><hr><br>');
        this.props.setPendingCampaign(data.campaign_distribution);
        this.props.setModalContent(html_content);
        this.props.openModal();
      }
    })
  }

  selectControl = (stories, eventType) => {
    if (eventType in stories && eventType in this.props.activeStory && stories[eventType].length > 0) {
      return (<select value={this.props.activeStory[eventType]} onChange={this.onStoryChange.bind(this, eventType)}>
        {Array.from(stories[eventType]).map(title => <option key={title} value={title}>{title}</option>)}
      </select>)
    } else {
      return null;
    }
  };

  swatch = eventType => {
    return <span style={{
      position: "absolute",
      width: 15,
      height: 15,
      marginTop: 4,
      backgroundColor: eventType ? this.props.eventColors[eventType] : '#fff',
      opacity: 0.7,
      border: "1px solid black"
    }} />
  };

  eventControl = (stories, eventType, label) => {
    return <div key={eventType}><label>
      {this.swatch(eventType)}
      <input type="checkbox"
            name={eventType}
            checked={this.props.eventTypeSelection[eventType]}
            onChange={this.onEventTypeChange.bind(this, eventType)} />{label}&nbsp;
      {this.selectControl(stories, eventType)}
    </label></div>
  };

  render() {
    const ControlPanel = this.props.containerComponent || defaultContainer;
    const { eventLabels, stories } = this.props;
    const startDate = this.state.dateList[this.state.curDateIdx];

    return (
        <div>
          <ControlPanel>
            <h2>Recent Events</h2>
            <h3>{dateToStr(startDate)}</h3>
            <section>
              {Object.entries(eventLabels).map(([eventType, label]) =>
                this.eventControl(stories, eventType, label)
              )}
            </section>
            <section>
              <label>Time Period
                <input
                    type="range"
                    min={0}
                    max={this.state.dateList.length - 1}
                    value={this.state.curDateIdx}
                    onChange={this.onDateChange.bind(this)}
                />
              </label>
            </section>
            <section>
              <label>Event Sensitivity
                <input
                    type="range"
                    min={1}
                    max={3}
                    step={0.1}
                    value={this.props.zscore}
                    onChange={this.onZscoreChange.bind(this)}
                />
                <span>{this.props.zscore} {'\u03C3'}</span>
              </label>
            </section>
            <section>
              <button onClick={this.prepareDistribution.bind(this)}>Prepare Distribution</button>
            </section>
          </ControlPanel>

          {this.props.children}
        </div>
    );
  }
}
