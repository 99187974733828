import React, {Component} from "react";
import Map from "../containers/map";
import ControlPanel from "../containers/control-panel";
import {DATA_END_DATE, DATA_START_DATE, RECENT_EVENT_DAYS} from "../constants";
import RecommendationTable from "../components/recommendation-table";
import Modal from "../containers/modal";

class Console extends Component {
  render() {
    return (
        <div style={{height: "100%", width: "100%"}}>
          <div style={{height: "50%", width: "100%"}}>
            <Map/>
            <ControlPanel
                numDays={RECENT_EVENT_DAYS}
                dataStartDate={DATA_START_DATE}
                dataEndDate={DATA_END_DATE}
            >
            </ControlPanel>
          </div>
          <RecommendationTable/>
          <Modal/>
        </div>
    );
  }
}

export default Console;