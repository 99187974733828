import {connect} from 'react-redux';
import { toggleModal, setModalContent } from '../actions';
import { setEventDates, setSelectedEventTypes, setEventMinZScore } from '../features/event/eventSlice';
import { setPendingCampaign } from '../features/recommendation/recommendationSlice';
import { setActiveStory } from '../features/story/storySlice';
import ControlPanel from '../components/control-panel';


const mapDispatchToProps = dispatch => ({
  setDate: date => {
    const eventDates = { startDate: date, endDate: date.plus({days: 1}) };

    dispatch(setEventDates(eventDates));
  },
  setStory: (event_type, story) => {
    return dispatch(setActiveStory({event_type, story}));
  },
  setPendingCampaign: campaignID => {
    return dispatch(setPendingCampaign(campaignID));
  },
  setEventMinZScore: zscore => dispatch(setEventMinZScore(zscore)),
  onEventTypeSelect: eventTypes => {
    dispatch(setSelectedEventTypes(eventTypes));
  },
  openModal: () => dispatch(toggleModal()),
  setModalContent: content => dispatch(setModalContent(content))
});

const mapStateToProps = state => {
  const eventTypesSet = new Set(state.event.selectedEventTypes);
  const eventTypeSelection = state.event.eventTypes.reduce((obj, item) => {
    obj[item.name] = eventTypesSet.has(item.name);
    return obj;
  }, {});
  const eventTypes = state.event.eventTypes.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});
  const eventLabels = state.event.eventTypes.reduce((obj, item) => {
    obj[item.name] = item.label;
    return obj;
  }, {});
  const eventColors = state.event.eventTypes.reduce((obj, item) => {
    obj[item.name] = item.color;
    return obj;
  }, {});

  return {
    selection: state.recommendations.selection,
    recommendations: state.recommendations.recommendations,
    stories: state.story.stories,
    activeStory: state.story.activeStory,
    eventTypeSelection,
    eventTypes,
    eventLabels,
    eventColors,
    zscore: state.event.eventMinZScore,
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ControlPanel);