import { createSlice } from "@reduxjs/toolkit";

import { getStories } from "../../utils";

export const storySlice = createSlice({
  name: 'story',
  initialState: {
    activeStory: {},
    stories: {},
  },
  reducers: {
    setActiveStory: (state, action) => {
      // Note that immutability is retained because `createSlice` uses the Immer library.
      state.activeStory[action.payload.event_type] = action.payload.story;
    },
    setStories: (state, action) => {
      const { recommendations, eventTypes } = action.payload;
      const stories = state.stories = getStories(recommendations, eventTypes);

      // For every event type, if there are multiple stories,
      // initialize the story for that type to the first story.
      Object.keys(eventTypes).forEach(type => {
        if (type in stories && stories[type].length > 0) {
          state.activeStory[type] = stories[type][0];
        } else {
          state.activeStory[type] = null;
        }
      });
    },
  },
});

export const { setActiveStory, setStories } = storySlice.actions;

export default storySlice.reducer;
