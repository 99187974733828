export const ActionTypes = {
  SET_GRAPHICS_LIST: 'SET_GRAPHICS_LIST',
  SET_STORY: 'SET_STORY',
  SELECT_GRAPHIC: 'SET_GRAPHIC',
  TOGGLE_USER: 'TOGGLE_USER',

  TOGGLE_MODAL_OPEN: 'TOGGLE_MODAL_OPEN',
  SET_MODAL_CONTENT: 'SET_MODAL_CONTENT',
  SET_SEND_IN_PROGRESS: 'SET_SEND_IN_PROGRESS',
  SET_DID_SUCCESSFULLY_SEND: 'SET_DID_SUCCESSFULLY_SEND',
};

export const setGraphics = graphics => ({
  type: ActionTypes.SET_GRAPHICS_LIST,
  payload: {
    graphics
  }
});

export const setStory = (event_type, story) => ({
  type: ActionTypes.SET_STORY,
  payload: {
    event_type,
    story
  }
});

export const selectGraphic = url => ({
  type: ActionTypes.SELECT_GRAPHIC,
  payload: {
    url
  }
});

export const toggleUser = url => ({
  type: ActionTypes.TOGGLE_USER,
  payload: {
    url
  }
});

export const toggleModal = () => ({
  type: ActionTypes.TOGGLE_MODAL_OPEN,
});

export const setSendInProgress = state => ({
  type: ActionTypes.SET_SEND_IN_PROGRESS,
  payload: {
    state
  }
});

export const setDidSuccessfullySend = state => ({
  type: ActionTypes.SET_DID_SUCCESSFULLY_SEND,
  payload: {
    state
  }
});

export const setModalContent = content => ({
  type: ActionTypes.SET_MODAL_CONTENT,
  payload: {
    content
  }
});